import FavoriteIcon from "@mui/icons-material/Favorite";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import {
  Box,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { doc, DocumentData, setDoc } from "firebase/firestore";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { PageHeader } from "../components/Header";
import { Person, recalculateStats, StatsType } from "../data/models";
import { firestore } from "../firebase";
import { formatDate, formatPriceToCZK, getLastNDays } from "../helpers/helpers";
import { useDataHook } from "../hooks/dataHook";

type OnRatingType = (
  date: Date,
  personId: string,
  rating: number
) => Promise<void>;

const PeopleCard: React.FC<{
  date: Date;
  person: Person;
  tasks: string[];
  onRating: OnRatingType;
  data: DocumentData | undefined;
}> = ({ date, person, tasks, onRating, data }) => {
  const ratingCompleted =
    data?.ratings?.[date.toISOString().split("T")[0]]?.[person.id]?.percent ??
    null;

  const opacitySelected = 0.7;
  const opacity = ratingCompleted ? 0.3 : 1;

  return (
    <Card
      sx={{ marginBottom: 2, display: "flex", justifyContent: "space-between" }}
    >
      <Box>
        <CardContent>
          <Typography variant="h6">{person.name}</Typography>

          {tasks.map((task) => (
            <Chip label={task} key={task} variant="outlined" size="small" />
          ))}
        </CardContent>
        <CardActions>
          <ButtonGroup size="large">
            <IconButton
              size="small"
              aria-label="happy face"
              style={{
                backgroundColor: "green",
                color: "white",
                opacity: ratingCompleted == 100 ? opacitySelected : opacity,
              }}
              onClick={() => onRating(date, person.id, 100)}
            >
              <SentimentSatisfiedAltIcon />
            </IconButton>
            <IconButton
              size="small"
              aria-label="neutral face"
              style={{
                backgroundColor: "orange",
                color: "white",
                opacity: ratingCompleted == 50 ? opacitySelected : opacity,
              }}
              onClick={() => onRating(date, person.id, 50)}
            >
              <SentimentNeutralIcon />
            </IconButton>
            <IconButton
              size="small"
              aria-label="sad face"
              style={{
                backgroundColor: "red",
                color: "white",
                opacity: ratingCompleted == 1 ? opacitySelected : opacity,
              }}
              onClick={() => onRating(date, person.id, 1)}
            >
              <SentimentDissatisfiedIcon />
            </IconButton>
            <IconButton
              size="small"
              aria-label="love"
              style={{
                backgroundColor: "#b500aa",
                color: "white",
                opacity: ratingCompleted == 150 ? opacitySelected : opacity,
              }}
              onClick={() => onRating(date, person.id, 150)}
            >
              <FavoriteIcon />
            </IconButton>
          </ButtonGroup>
        </CardActions>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 80 }}
        image={person.avatar}
        alt="Live from space album cover"
      />
    </Card>
  );
};

const Stats: React.FC<{
  people: Person[];
  stats: StatsType;
}> = ({ people, stats }) => {
  return (
    <Card>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 0 }}
      >
        {people?.map((person, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              width: `${100 / people.length}%`,
              height: 100,
              overflow: "hidden",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                flexShrink: 0,
              }}
              image={person.avatar}
              alt={`${person.name}'s avatar`}
            />
            <Chip
              label={formatPriceToCZK(stats[person.id] ?? 0)}
              sx={{
                position: "absolute",
                bottom: 8,
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "white",
              }}
            />
          </Box>
        ))}
      </Box>
    </Card>
  );
};

const RatingForDay: React.FC<{
  date: Date;
  people: Person[];
  tasksForPersonMap: Record<string, string[]>;
  onRating: OnRatingType;
  data: DocumentData | undefined;
}> = ({ date, people, tasksForPersonMap, onRating, data }) => {
  return (
    <>
      <Typography variant="h6">{formatDate(date)}</Typography>
      <Grid container spacing={2} marginBottom={5}>
        <Grid item xs={12}>
          {people.map((person) => (
            <PeopleCard
              key={person.id}
              date={date}
              person={person}
              tasks={tasksForPersonMap[person.id]}
              onRating={onRating}
              data={data}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

const PeopleRating: React.FC = () => {
  //const [ratings, setRatings] = useState<Ratings>({});

  const {
    localUpdate,
    setLocalUpdate,
    tasks,
    setTasks,
    people,
    pointsMap,
    tasksForPersonMap,
  } = useDataHook("kmochovi");

  const [ratingsValue, ratingsLoading, ratingsError] = useDocumentData(
    doc(firestore, "ratings", "kmochovi")
  );

  const onRating: OnRatingType = async (date, personId, rating) => {
    console.log(date, personId, rating);
    const docRef = doc(firestore, "ratings", "kmochovi");

    const newRatings = { ...ratingsValue?.ratings };
    // Ensure the date and personId exist in the ratings object
    const dateString = date.toISOString().split("T")[0];
    if (!newRatings[dateString]) {
      newRatings[dateString] = {};
    }
    if (!newRatings[dateString][personId]) {
      newRatings[dateString][personId] = { percent: 0 };
    }

    // Update the rating
    newRatings[dateString][personId].percent = rating;

    // Recalculate the stats
    const currentDate = new Date();
    const stats = recalculateStats(newRatings, currentDate);

    currentDate.setMonth(currentDate.getMonth() - 1);
    currentDate.setDate(1);
    const statsFromPreviousMonth = recalculateStats(newRatings, currentDate);
    try {
      await setDoc(docRef, {
        ratings: newRatings,
        stats: stats,
        statsPrev: statsFromPreviousMonth,
      });
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("Chyba při ukládání hodnocení do databáze: " + error);
    }
  };

  const lastDates = getLastNDays(5);

  const previousContent = () => {
    return (
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Předchozí měsíc</Typography>
        <Stats people={people} stats={ratingsValue?.statsPrev} />
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <PageHeader
          title="Kontrola splnění úkolů"
          description="Označte, jak se dětem dařilo s úkoly"
        />
      </Box>
      <Container>
        {new Date().getDate() <= 7 ? previousContent() : <div></div>}
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h6">Aktuální měsíc</Typography>
          <Stats people={people} stats={ratingsValue?.stats} />
        </Box>
        {lastDates.map((date) => (
          <RatingForDay
            date={date}
            people={people}
            tasksForPersonMap={tasksForPersonMap}
            onRating={onRating}
            data={ratingsValue}
          />
        ))}
      </Container>
    </>
  );
};

export default PeopleRating;
